import React, { useRef, useEffect } from 'react';
import WebViewer from '@pdftron/pdfjs-express-viewer';
import axios from 'axios';
import './App.css';

const App = () => {
  const viewer = useRef(null);
  const baseURL = "https://c3glide-d9g5.boldstratus.com/api/";
  let queryParams = new URLSearchParams(document.location.search); // The query parameters set in the url
  let totalPages = 1; // The total number of pages inside the pdf, set in afterLoadComplete()
  let currentPageNo = 1; // A reference to the current page number we are on
  let wvInstance = null;

  // if using a class, equivalent of componentDidMount 
  useEffect(() => {
    window.addEventListener("message", (event) => {
      onReceiveMessage(event.data);
    }, false);

    WebViewer(
      {
        path: '/webviewer/lib',
        licenseKey: '7iR0D4IUNYqoRrZCpXu6',
        disabledElements: ['toolsHeader', 'header'],
        css: '../assets/style.css',
      },
      viewer.current,
    ).then((instance) => {
      wvInstance = instance;
      const { Core } = instance;
      instance.UI.setMinZoomLevel('50%');
      if(queryParams.get('accessToken') && queryParams.get('id') && queryParams.get('contentSource')) {
        currentPageNo = parseInt(queryParams.get('page'));
        loadPDFPage(queryParams.get('page'));
      }

      // adding an event listener for when a document is loaded
      Core.documentViewer.addEventListener('documentLoaded', () => {
        console.log('document loaded');
        wvDocumentLoadedHandler();
      });
    });
  }, []);

  const base64ToBlob = (base64) => {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }
  
    return new Blob([bytes], { type: 'application/pdf' });
  };

  const loadPDFPage = (page) => {
    wvInstance.openElements(['loadingModal']);
    axios.get(baseURL+"v1/c3/getRecordPageContent?contentSource="+queryParams.get('contentSource')+"&id="+queryParams.get('id')+"&page="+page, 
      {
        headers: {
          'Authorization': "Bearer "+queryParams.get('accessToken')
        }
      }
    )
    .then(response => {
      wvInstance.UI.loadDocument(base64ToBlob(response.data.pdf), { filename: 'myfile.pdf' });
      totalPages = response.data.pages;
    })
    .catch(error => {});
  }

  function wvDocumentLoadedHandler() {
    // you can access the instance object for low-level APIs
    const instance = wvInstance;
    instance.closeElements(['loadingModal']);
  }

  function onReceiveMessage(data) {
    try {
      let jsonData = JSON.parse(data);
      if(jsonData.cmd === "nextPage" && currentPageNo+1 <= totalPages) {
        goNextPage();
      }
      if(jsonData.cmd === "prevPage" && currentPageNo-1 > 0) {
        goPrevPage();
      }
    }
    catch(e) {
      // Sometimes we get non json messages, just ignore it
    }
  }

  function goNextPage() {
    if((parseInt(currentPageNo) + 1) <= totalPages) {
      currentPageNo = parseInt(currentPageNo) + 1;
      loadPDFPage(currentPageNo);
    }
  }

  function goPrevPage() {
    if((parseInt(currentPageNo)-1) > 0) {
      currentPageNo = (parseInt(currentPageNo) - 1);
      loadPDFPage(currentPageNo);
    }
  }

  return (
    <div className="App">
      <div className="webviewer" ref={viewer}></div>
    </div>
  );
};

export default App;
